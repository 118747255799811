@import '@styles/settings/index';
@import '@styles/mixins/index';

.collapsible {
  width: 100%;
  border-bottom: 1px solid $COLOR_5;
  cursor: pointer;
  padding-bottom: 8px;

  @include tablet {
    padding-bottom: 16px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    span {
      @include font(18px, 32px);
      font-weight: 700;

      @include tablet {
        font-size: 24px;
        line-height: 36px;
        max-width: 628px;
      }
    }

    div.expand,
    div.collapse {
      padding: 0;
      border: none;
      background-color: transparent;
      margin-left: 18px;
      padding-bottom: 8px;
      cursor: pointer;

      @include tablet {
        margin-left: 36px;
      }

      svg {
        width: 16px;
        height: 8px;
        transition: all 0.2s ease-in-out;

        @include tablet {
          width: 32px;
          height: 16px;
        }

        path {
          stroke: $COLOR_2;
        }
      }
    }

    div.collapse svg {
      transform: rotate3d(150, 0, 0, 180deg);
    }
  }

  &__content {
    @include font(14px, 28px);
    overflow: hidden;
    transition: height 0.2s ease-in-out;

    @include tablet {
      max-width: 628px;
      font-size: 18px;
      line-height: 32px;
      font-weight: 600;
    }

    div {
      padding-top: 8px;

      @include tablet {
        padding-top: 16px;
      }
    }
  }
}
