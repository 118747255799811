@import 'variables';
@import '../mixins';

html {
  font-size: 16px;
  line-height: 2;
  font-family: $font-main;
  font-weight: 400;
  letter-spacing: -0.005em;
  color: $COLOR_2;
}

h1,h2,h3{
  font-family: $font-main;
  font-weight: 700;
  margin: 0 0 16px;
  color: $COLOR_2;
}

h1{
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.01em;

  @include tablet{
    font-size: 32px;
    line-height: 1.25;
  }
}

h2{
  font-size: 14px;
  line-height: 26px;

  @include tablet{
    font-size: 24px;
    line-height: 1.5;
  }
}

h3{
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.01em;

  @include tablet{
    font-size: 18px;
    line-height: 1.33;
  }
}

p,.regularText{
  font-size: 14px;
  line-height: 26px;

  @include tablet{
    font-size: 16px;
    line-height: 2;
  }
}

.subtitle{
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: 0.0025em;
}

.largeText{
  font-size: 12px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0.0025em;

  @include tablet{
    font-size: 18px;
    line-height: 32px;
  }
}

.styled-button {
  height: 48px;
  width: 100%;
  border-radius: 2px;
  background-color: $COLOR_11;
  color: $COLOR_1;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  border: none;
  cursor: pointer;
  transition: all 0.15s ease-in;
  &:disabled {
    background-color: $COLOR_15;
    cursor: default;
    border: 1px solid $COLOR_9;
    color: $COLOR_9;
  }
  &:hover:enabled {
    background-color: $COLOR_12;
  }
  &:focus:enabled {
    background-color: $COLOR_13;
  }
}

.underlinedBlock{
  border-bottom: 1px solid $COLOR_5;
}

a{
  font-family: $font-main;

  &.underlined-link{
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.005em;
    text-decoration-line: underline;
    color: $COLOR_2;
  }
}

