@import '@styles/settings/index';
@import '@styles/mixins/index';

.notification {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: $COLOR_16;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: $COLOR_8;
  color: $COLOR_8;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  font-weight: bold;
  padding: 3px 5vw;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.13);

  &.sticky {
    position: fixed;
  }

  &.fading {
    animation: fadeOut 4s linear;
    opacity: 0;
  }

  &.error {
    color: $COLOR_10;
    border-color: $COLOR_10;
  }

  &.warning {
    color: $COLOR_PURPLE;
    border-color: $COLOR_PURPLE;
  }
  &.info {
    color: $COLOR_GREEN;
    border-color: $COLOR_GREEN;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
