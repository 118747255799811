@import '@styles/mixins';
@import '@styles/settings/colors';

.news-panel {
  padding: 40px 20px 60px;
  background: $COLOR_11;

  @include tablet {
    padding: 40px 121px 46px;
  }

  @include desktop {
    padding: 30px 80px 24px;
    flex-direction: row;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    margin: auto;

    @include desktop {
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
    }
  }

  &__title {
    @include font(24px, 32px);
    font-weight: 700;
    letter-spacing: -0.01em;
    color: $COLOR_1;
    text-align: center;
    padding-bottom: 20px;

    @include tablet {
      padding-bottom: 24px;
      line-height: 24px;
    }

    @include desktop {
      text-align: start;
      padding-top: 18px;
      padding-bottom: 0;
    }
  }

  &__content {
    width: 100%;

    @include desktop {
      max-width: 617px;
      margin-left: 25px;
    }

    span {
      @include font(11px, 16px);
      display: none;
      letter-spacing: -0.01em;
      color: $COLOR_1;
      margin-top: 8px;

      @include desktop {
        display: block;
      }
    }
  }

  &__input {
    display: flex;
    position: relative;
    align-content: center;
    border: 1px solid rgba(10, 190, 117, 0.6);
    border-radius: 2px;
    height: 60px;

    @include desktop {
      max-width: 617px;
    }

    input {
      @include font(16px, 24px);
      letter-spacing: -0.01em;
      color: $COLOR_1;
      width: calc(100% - 82px);
      border: none;
      background-color: transparent;
      padding-left: 10px;

      @include tablet {
        padding-left: 32px;
        width: calc(100% - 130px);
      }

      @include desktop {
        font-size: 14px;
      }

      &::placeholder {
        color: $COLOR_1;
      }
    }

    button {
      @include font(14px, 24px);
      font-weight: 600;
      letter-spacing: -0.01em;
      color: $COLOR_1;
      position: absolute;
      right: 3px;
      top: 3px;
      width: 73px;
      height: 52px;
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 1px;

      @include tablet {
        width: 121px;
      }
    }
  }
}
