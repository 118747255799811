@import '@styles/settings/index';

.create-account {
  .existing-login-btn {
    font-family: $font-main;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.01em;
    text-align: left;
    color: $COLOR_2;
    text-decoration: underline;
    margin-top: 32px;
    margin-bottom: 24px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
  }

  .form-text {
    display: block;
    font-family: $font-main;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    color: $COLOR_6;
    margin-bottom: 16px;
  }

  &__form {
    border-bottom: solid 1px $COLOR_5;
    padding-bottom: 32px;
    display: grid;
    grid-template-columns: minmax(auto, 296px) minmax(auto, 296px);
    grid-template-rows: auto auto auto auto auto;
    column-gap: 32px;
    row-gap: 24px;
  }

  &__name-field {
    display: inherit;
    align-self: end;

    label {
      font-family: $font-main;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.0025em;
      color: $COLOR_2;
    }

    input {
      height: 46px;
      width: 100%;
      max-width: 296px;
      border: 1px solid $COLOR_9;
      border-radius: 2px;
      padding: 16px 24px 16px 24px;
      color: $COLOR_2;
      font-family: $font-main;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: -0.01em;
    }
  }

  &__error-msg {
    font-family: $font-main;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.005em;
    color: $COLOR_10;
  }

  &__email-field {
    @extend .create-account__name-field;
    grid-column: 1;

    &--error {
      border: 1px solid $COLOR_10;
    }
  }

  &__confirm-email-field {
    @extend .create-account__name-field;
    grid-column: '2';
    grid-row: '2';
    display: 'inherit';
  }

  &__password-field {
    grid-column: 1;
    display: inherit;
    align-self: end;

    label {
      font-family: $font-main;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.0025em;
      color: $COLOR_2;
    }

    div {
      position: relative;

      input {
        height: 46px;
        width: 100%;
        max-width: 296px;
        border: 1px solid $COLOR_9;
        border-radius: 2px;
        padding: 16px 24px 16px 24px;
        color: $COLOR_2;
        font-family: $font-main;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: -0.01em;
      }

      button {
        font-family: $font-main;
        font-size: 12px;
        font-weight: 700;
        line-height: 11px;
        letter-spacing: -0.005em;
        position: absolute;
        right: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-decoration: underline;
        padding: 17px 24px 17px 8px;
      }
    }
  }

  &__confirm-password-field {
    @extend .create-account__password-field;
    grid-column: 2;
    grid-row: 3;
  }

  &__info {
    @extend .form-text;
    color: $COLOR_6;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &__submit-btn {
    grid-column: 1;
    width: 100%;
    height: 46px;
    max-width: 296px;
    background-color: $COLOR_11;
    color: $COLOR_1;
    font-family: $font-main;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.01em;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.15s ease-in;
    &:disabled {
      background-color: $COLOR_7;
      cursor: default;
      border: 1px solid $COLOR_9;
      color: $COLOR_9;
    }
    &:hover:enabled {
      background-color: $COLOR_12;
    }
    &:focus:enabled {
      background-color: $COLOR_13;
    }
  }
}
