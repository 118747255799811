@import '@styles/settings/index';
@import '@styles/mixins/index';
.active-validation-field{
  padding-bottom: 16px;

  @include tablet{
    padding-bottom: 24px;
  }

  label {
    display: inline-block;
    line-height: 26px;
    padding-right: 28px;
    position: relative;
    margin-bottom: 4px;
  }
  input,select{
    display: block;
    margin-bottom: 0;
    @include font(14px, 14px);
    box-sizing: border-box;
    height: 46px;
    width: 100%;
    border: 1px solid $COLOR_9;
    padding: 16px 24px 16px 24px;
  }
  select{
    appearance: none;
    -webkit-appearance: none;
    padding: 10px 24px 10px 24px;
    line-height: 20px;
  }
  input,select{
    border-color: $COLOR_9;
    margin-bottom: 0;
    font-weight: 600;
  }
  .select-wrapper{
    position: relative;
    &::after {
      height: 46px;
      content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yIDJMNi41IDYuNUwxMSAyIiBzdHJva2U9IiMzMTMxMzEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+Cjwvc3ZnPgo=');
      position: absolute;
      display: flex;
      align-items: center;
      right: 22px;
      top: 0;
      pointer-events: none;

      @include tablet {
        right: 27px;
      }
    }
  }
  &.validation-success{
    label{
      &:after{
        display: block;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 26px;
        height: 26px;
        background: url("/assets/images/check-green.svg") center no-repeat;
        background-size: 12px 12px;
      }
    }
    input,select{
      border-color: $COLOR_12;
    }
    &.validation-error{
      input,select{
        border-color: $COLOR_10;
      }
    }
  }
  &.validation-error{
    label{
      color:$COLOR_10;
      &:after{
        display: none;
      }
    }
    input,select{
      border-color: $COLOR_10;
    }
  }
  span.error{
    display: block;
    color:$COLOR_10;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    padding-top: 8px;
  }
}

.form-description{
  color:$COLOR_6;
  margin-bottom: 16px;
}

.row-description{
  flex:1 1 100%;
  width: 100%;
  color:$COLOR_6;
  padding-top: 8px;

  ul.validation-requirements{
    list-style: none;
    li{
      display: block;
      padding-left:20px;
      position: relative;
      margin-bottom: 4px;
      @include font(16px,32px);
      color:$COLOR_6;

      &:before{
        position: absolute;
        width: 12px;
        height: 12px;
        top: 10px;
        left: 0;
        display: block;
        content: '';
        border-radius: 50%;
      }
      &.match{
        color: $COLOR_12;
        font-weight: bold;
        &:before{
          background: url("/assets/images/check-green.svg") center no-repeat;
          background-size: 12px 12px;
        }
      }
    }
    .sub-validation{
      padding: 0;
      ul{
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li{
          flex: 1 1 90%;
          margin-left: 20px;
          @include tablet {
            flex: 1 1 40%;
          }
        }
      }
    }
  }
}

.form-row{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 16px;

  .active-validation-field{
    padding-bottom: 16px;
    @include tablet {
      padding-bottom: 0;
    }
  }

  &.double{
    justify-content: flex-start;
    padding-bottom: 0;
    @include tablet {
      justify-content: space-between;
      padding-bottom: 24px;
    }
  }

  @include tablet {
    justify-content: flex-start;
    padding-bottom: 24px;

    &.double{
      justify-content: space-between;
    }

    .active-validation-field{
      padding-bottom: 0;
    }
  }
}

.page-title{
  margin-bottom: 24px;
}

.submit-block{
  padding-top: 8px;
  @include tablet{
    padding-top: 0;
    button{
      max-width: 296px;
    }
  }
}

//CHECKBOX
.checkbox-wrapper{
  label{
    @include font(16px,32px);
    padding-left: 32px;
    position: relative;
    display: block;
    letter-spacing: -0.005em;

    &:before,&:after{
      position: absolute;
      top: 6px;
      display: block;
      content: '';
      width: 18px;

    }
    &:before{
      left: 0;
      height: 16px;
      border-radius: 3px;
      border:2px solid $COLOR_2;
    }
    &:after{
      left: 3px;
      font-weight: bold;
      height: 17px;
      font-size: 17px;
      line-height: 17px;
      color:$COLOR_2;
      display: none;
      content: '\2573';
    }
  }

  input{
    display: none;
    &:checked + label{
      &:after{
        display: block;
      }
    }
  }
}

