@import '@styles/settings/index';
@import '@styles/mixins';

.page404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 115px 16px;
  text-align: center;

  &__message {
    @include font(24px, 34px);
    font-weight: 600;
    margin-bottom: 40px;
  }

  &__help {
    @include font(14px, 24px);
    display: flex;
    flex-direction: column;
    align-items: center;

    &-link {
      color: $COLOR_11;
      font-weight: 600;

      &:hover {
        color: $COLOR_11;
        text-decoration: underline;
      }
    }
  }
}
