@import '@styles/settings/index';
@import '@styles/mixins/index';

.primary-cta {
  @include font(14px, 24px);
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background-color: $COLOR_11;
  color: $COLOR_1;
  font-weight: 600;
  min-height: 46px;
  cursor: pointer;
  transition: all 0.15s ease-in;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  width: 100%;

  @include tablet {
    padding: 10px 24px;
    width: auto;
  }

  &:disabled {
    background-color: $COLOR_15;
    cursor: default;
    border: 1px solid $COLOR_9;
    color: $COLOR_9;
  }
  &:hover:enabled {
    background-color: $COLOR_12;
  }
  &:focus:enabled {
    background-color: $COLOR_13;
    color: rgba(255, 255, 255, 0.8);
  }
}

.secondary-cta {
  @include font(14px, 24px);
  box-sizing: border-box;
  border: 1px solid $COLOR_9;
  border-radius: 2px;
  background-color: $COLOR_1;
  color: $COLOR_2;
  font-weight: 600;
  min-height: 46px;
  cursor: pointer;
  transition: all 0.15s ease-in;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  width: 100%;

  @include tablet {
    padding: 10px 24px;
  }

  &:disabled {
    background-color: $COLOR_15;
    cursor: default;
    border: 1px solid $COLOR_9;
    color: $COLOR_9;
  }
  &:hover:enabled {
    background-color: $COLOR_14;
    border: 1px solid $COLOR_14;
    color: $COLOR_1;
  }
  &:focus:enabled {
    background-color: $COLOR_17;
    color: rgba(255, 255, 255, 0.8);
  }
}
