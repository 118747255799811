@import '@styles/settings/index';
@import '@styles/mixins/index';

.stack-panel-img {
  height: 518px;
  width: 100%;
  position: relative;
  padding: 24px 16px;

  @include tablet {
    height: 300px;
    display: flex;
    align-items: center;
    padding: 16px 0 16px 76px;

    @include desktop {
      justify-content: center;
      padding: 16px 0;
    }
  }

  &__overlay-img {
    background-size: cover;
    background-position: 85% 0;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  &__content-wrapper {
    position: inherit;
    background-color: $COLOR_1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 2px;

    @include tablet {
      max-width: 524px;
      padding: 16px 32px;
      height: 100%;
      justify-content: center;
    }

    @include desktop {
      margin-left: -492px;
    }
  }

  h3 {
    @include font(18px, 28px);
    font-weight: 700;
    margin-bottom: 16px;

    @include tablet {
      font-size: 24px;
      line-height: 36px;
    }
  }

  p {
    @include font(14px, 28px);

    @include tablet {
      font-size: 16px;
      line-height: 32px;
    }
  }

  &__cta-wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    @include tablet {
      flex-direction: row;
      align-items: center;
      margin-top: 40px;
    }
  }

  button {
    @include tablet {
      min-width: 188px;
      width: fit-content;
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;

    @include tablet {
      margin-right: 40px;
    }
  }

  &__learn-more {
    @include font(14px, 14px);
    font-weight: 600;
    color: $COLOR_3;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      opacity: 0.8;
    }

    &::after {
      content: ' ';
      display: inline-block;
      height: 5px;
      width: 5px;
      margin-left: 13px;
      border: solid $COLOR_3;
      border-width: 0 2px 2px 0;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      vertical-align: middle;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
