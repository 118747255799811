@import '@styles/settings/index';
@import '@styles/mixins';

.pdp-basic{
  .hero-img-wrapper:first-child {
    margin-bottom: 40px;

    @include tablet {
      margin: 0;
    }
  }
}

.content-offset {
  @include tablet {
    margin-bottom: 75px;
  }

  @include desktop {
    margin-bottom: 150px;
  }
}
