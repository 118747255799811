@import '@styles/settings/index';
@import '@styles/mixins/index';

.text-panel-wrapper {
  padding: 0 16px;
}

.text-panel {
  text-align: center;
  max-width: 788px;
  width: 100%;
  margin: auto;

  h3 {
    @include font(24px, 32px);
    display: block;
    font-weight: 700;
    color: $COLOR_2;
    padding-bottom: 8px;
    border-bottom: 1px solid $COLOR_5;
    margin-bottom: 8px;

    @include tablet {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 16px;
    }
  }

  p {
    @include font(16px, 28px);
    display: block;
    color: $COLOR_2;

    @include tablet {
      line-height: 32px;
    }
  }
}
