@import '@styles/settings/index';
@import '@styles/mixins/index';

.active-validation-field {
  .show-hide-wrapper{
    position: relative;
    width: 100%;
    flex-basis: 100%;
    input,
    select {
      padding-right: 60px;
    }
  }
}

//password btn
.show-password-btn {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 0 24px 0 0;
  height: 46px;
  line-height: 46px;
  bottom: 0;
  right: 0;
  font-weight: 700;
  font-size: 12px;
  text-decoration: underline;
  color: $COLOR_2;
  user-select: none;
  -webkit-user-select: none;
}
