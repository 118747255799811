$COLOR_1: #ffffff;
$COLOR_2: #313131;
$COLOR_3: #000000;
$COLOR_4: #dfdfdf;
$COLOR_5: #c4c4c4;
$COLOR_6: #5a5a5a;
$COLOR_7: #f4f4f4;
$COLOR_8: #d9d9d9;
$COLOR_9: #adadad;
$COLOR_10: #d85139;
$COLOR_11: #094f34;
$COLOR_12: #0abe75;
$COLOR_13: #042518;
$COLOR_14: #838383;
$COLOR_15: #f5f5f5;
$COLOR_16: #ededed;
$COLOR_17: #121212;

$COLOR_PURPLE: #891c5b;
$COLOR_GREEN: $COLOR_11;
