/** These are mixins for mobile-first approach */
@import "@styles/settings/variables";
@mixin mobile-portrait {
  @media (min-width: #{$mobile-sm}) and (orientation: portrait) {
    @content;
  }
}
@mixin mobile-sm {
  @media (min-width: #{$mobile-sm}) {
    @content;
  }
}
@mixin mobile-md-portrait {
  @media (min-width: #{$mobile-md}) and (orientation: portrait) {
    @content;
  }
}
@mixin mobile-md {
  @media (min-width: #{$mobile-md}) {
    @content;
  }
}
@mixin mobile-lg {
  @media (min-width: #{$mobile-lg-width}) {
    @content;
  }
}
@mixin mobile-landscape {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin tablet-lg {
  @media (min-width: #{$tablet-lg-width}) {
    @content;
  }
}
@mixin tablet-lg-landscape {
  @media (min-width: #{$tablet-lg-width}) and (orientation: landscape) {
    @content;
  }
}
@mixin tablet-lg-portrait {
  @media (min-width: #{$tablet-lg-width}) and (orientation: portrait) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin desktop-lg {
  @media (min-width: #{$desktop-lg-width}) {
    @content;
  }
}
