@import '@styles/settings/index';
@import '@styles/mixins/index';

.billing-info {
  margin-bottom: 24px;

  @include tablet {
    margin-bottom: 40px;
  }

  .checkbox-checked,
  .checkbox-unchecked {
    min-width: 16px;
    width: 16px;
    margin: 4px 8px 0 0;

    @include tablet {
      margin: 6px 8px 0 0;
    }
  }

  &__reqd {
    display: block;
    color: $COLOR_6;
    margin-bottom: 16px;
  }

  &__info {
    display: block;
    color: $COLOR_6;
    margin: 16px 0;

    @include tablet {
      max-width: 459px;
      margin: 32px 0 24px 0;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    column-gap: 32px;
    row-gap: 24px;

    .active-validation-field{
      padding-bottom: 0;
    }

    @include tablet {
      grid-template-columns: minmax(auto, 296px) minmax(auto, 131px);
      border-bottom: solid 1px $COLOR_5;
      padding-bottom: 32px;
    }
  }

  &__name-field {
    grid-column: 1;

    @include tablet {
      display: block;
    }

    label {
      @include font(14px, 26px);
      letter-spacing: 0.0025em;
    }

    input {
      @include font(14px, 14px);
      box-sizing: border-box;
      height: 46px;
      width: 100%;
      border: 1px solid $COLOR_9;
      border-radius: 2px;
      padding: 16px 24px 16px 24px;
      font-weight: 600;
      letter-spacing: -0.01em;

      &.input-success{
        border-color: $COLOR_12;
      }
      &.input-error{
        border-color: $COLOR_10;
      }

      @include tablet {
        max-width: 296px;
      }
    }

    //iOS hack disabling zoom on focus input field
    @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
      select,
      textarea,
      input {
        font-size: 16px;
      }
    }

    &--error {
      @extend .billing-info__name-field;

      input {
        border: 1px solid $COLOR_10;
      }

      span {
        @include font(16px, 32px);
        font-weight: 700;
        letter-spacing: -0.005em;
        color: $COLOR_10;
      }
    }
  }

  &__address-field {
    @extend .billing-info__name-field;

    div {
      @include tablet {
        display: block;
      }
    }

    div:nth-child(2) {
      margin-top: 24px;
    }
  }

  &__add-address-btn {
    @include font(16px, 32px);
    font-weight: 700;
    letter-spacing: -0.01em;
    text-decoration: underline;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    position: relative;
    display: flex;
    grid-row: 3;
    align-items: center;
    transition: all 0.15s ease-in;

    &:hover {
      opacity: 0.8;
    }

    .plus-icon {
      margin-right: 8px;
      min-width: 16px;
    }
  }

  &__zip-field,
  &__city-field,
  &__phone-field {
    @extend .billing-info__name-field;
  }

  &__state-field {
    grid-column: 1;

    @include tablet {
      grid-column: 2;
    }

    label {
      @include font(14px, 26px);
      letter-spacing: 0.0025em;
    }

    div {
      position: relative;

      @include tablet {
        max-width: 296px;
      }

      &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yIDJMNi41IDYuNUwxMSAyIiBzdHJva2U9IiMzMTMxMzEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+Cjwvc3ZnPgo=');
        position: absolute;
        right: 22px;
        top: 16px;
        pointer-events: none;

        @include tablet {
          right: 22px;
          max-width: auto;
        }
      }

      select {
        @include font(14px, 14px);
        appearance: none;
        -webkit-appearance: none;
        height: 46px;
        width: 100%;
        border: 1px solid $COLOR_9;
        border-radius: 2px;
        padding: 10px 24px 10px 24px;
        font-weight: 600;
        letter-spacing: -0.01em;
        background-color: $COLOR_1;

        @include tablet {
          max-width: 131px;
        }
      }
    }
  }

  &__payment-agreement,
  &__offers-agreement {
    color: $COLOR_2;
    display: flex;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;

    @include tablet {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}
