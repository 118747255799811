@import '@styles/settings/index';
@import '@styles/mixins/index';

.four-panel {
  display: grid;
  gap: 24px;
  justify-content: center;
  padding: 0 16px;

  @include desktop {
    grid-auto-flow: column;
    gap: 32px;
    padding: 0 32px;
  }

  &__panel-wrapper {
    width: 100%;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2));

    @include tablet {
      display: flex;
      max-width: 718px;
      align-self: center;
    }

    @include desktop {
      display: block;
      max-width: 296px;
      align-self: auto;
    }
  }

  &__img-container {
    width: 100%;
    height: 100px;

    @include tablet {
      width: 277px;
      height: auto;
    }

    @include desktop {
      height: 120px;
      max-width: 296px;
      width: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 2px 2px 0 0;

      @include tablet {
        width: 277px;
        border-radius: 2px 0 0 2px;
      }

      @include desktop {
        width: 100%;
        border-radius: 2px 2px 0 0;
      }
    }
  }

  &__content-wrapper {
    display: grid;
    background-color: $COLOR_17;
    gap: 12px;
    border-radius: 0 0 2px 2px;
    padding: 24px;

    @include tablet {
      min-height: 175px;
      justify-content: center;
      height: auto;
      width: 100%;
      padding: 24px 42px;
      border-radius: 0 2px 2px 0;
    }

    @include desktop {
      padding: 32px 24px;
      height: calc(100% - 120px);
    }
  }

  &__title {
    @include font(18px, 14px);
    font-weight: 700;
    color: $COLOR_1;
  }

  &__content {
    @include font(14px, 28px);
    color: $COLOR_1;
  }

  &__learn-more {
    @include font(14px, 14px);
    font-weight: 600;
    color: $COLOR_1;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }

    &::after {
      content: ' ';
      display: inline-block;
      height: 5px;
      width: 5px;
      margin-left: 13px;
      border: solid $COLOR_1;
      border-width: 0 2px 2px 0;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      vertical-align: middle;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
