@import '@styles/settings/index';
@import '@styles/mixins';

.page-create-account {

  width: 90%;
  max-width: 788px;
  margin: 24px auto 80px;
  @include font(16px, 32px);

  @include tablet {
    margin: 40px auto 160px;
  }

  h1{
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 8px;

    @include tablet {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 24px;
    }
  }

  .active-validation-field{
    width: 100%;
    @include tablet {
      width: 296px;
    }
  }

  .create-account{
    margin-bottom: 24px;
  }

  .inner-wrapper{
    max-width: 624px;
    margin: 0 auto;
  }

}
