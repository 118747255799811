@import '@styles/settings/index';
@import '@styles/mixins/index';

.change-billing-form-container {
  text-align: left;
  margin-top: 36px;

  @include tablet {
    margin: 0;
  }

  span {
    @include font(16px, 32px);
    color: $COLOR_6;
    display: block;
    margin-bottom: 16px;
  }

  .change-billing {
    display: grid;
    gap: 16px;

    label {
      @include font(14px, 26px);
    }

    input {
      @include font(14px, 14px);
      box-sizing: border-box;
      height: 46px;
      width: 100%;
      border-radius: 2px;
      padding: 16px 24px 16px 24px;
      font-weight: 600;
      letter-spacing: -0.01em;
    }

    &__state {
      label {
        @include font(14px, 26px);
        letter-spacing: 0.0025em;
      }

      div {
        position: relative;

        &::after {
          content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yIDJMNi41IDYuNUwxMSAyIiBzdHJva2U9IiMzMTMxMzEiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+Cjwvc3ZnPgo=');
          position: absolute;
          right: 22px;
          top: 9px;
          pointer-events: none;

          @include tablet {
            right: 22px;
            max-width: auto;
          }
        }

        select {
          @include font(14px, 14px);
          appearance: none;
          -webkit-appearance: none;
          height: 46px;
          width: 100%;
          border-radius: 2px;
          padding: 10px 24px 10px 24px;
          font-weight: 600;
          letter-spacing: -0.01em;
          background-color: $COLOR_1;
        }
      }
    }

    &__phone {
      margin: 0;
    }

    &__address {
      display: grid;
      gap: 16px;
    }

    &__add-address-btn {
      @include font(16px, 32px);
      font-weight: 700;
      text-decoration: underline;
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;

      .plus-icon {
        margin-right: 8px;
        min-width: 16px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .active-validation-field {
    padding: 0;
  }

  .error {
    margin: 0;
  }
}
