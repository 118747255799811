@import '@styles/settings/index';
@import '@styles/mixins/index';

.footer {
  display: flex;
  flex-direction: column;
  background: $COLOR_3;
  padding: 60px 16px 80px 16px;

  @include tablet {
    padding: 60px 48px 80px 48px;
  }

  @include desktop {
    padding: 60px 80px 40px 80px;
  }

  &__container {
    max-width: 1440px;
    margin: auto;
    width: 100%;
  }

  &__top {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0 70px 0;
    }

    @include desktop {
      justify-content: normal;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    border-top: solid 1px rgba(196, 196, 196, 0.4);
    padding-top: 32px;

    @include desktop {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  &__main-links {
    display: grid;
    row-gap: 16px;
    padding: 0 0 60px 0;

    @include tablet {
      align-content: start;
      padding: 0;
    }

    a {
      @include font(14px, 24px);
      font-weight: 700;
      letter-spacing: 0.01em;
      color: $COLOR_1;
      cursor: pointer;
    }

    a:nth-child(2) {
      margin-bottom: 32px;

      @include tablet {
        margin-bottom: 42px;
      }
    }
  }

  &__about-links {
    display: grid;
    row-gap: 16px;
    padding-bottom: 60px;

    @include tablet {
      align-content: start;
      padding: 0;
    }

    @include desktop {
      padding-left: 94px;
    }

    a:first-child {
      @include font(14px, 24px);
      font-weight: 700;
      letter-spacing: 0.01em;
      color: $COLOR_1;
    }

    a:not(:first-child) {
      @include font(15px, 24px);
      letter-spacing: 0.01em;
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
    }
  }

  &__account-links {
    display: grid;
    row-gap: 16px;
    padding-bottom: 60px;

    @include tablet {
      align-content: start;
      padding: 0;
      text-align: end;
    }

    @include desktop {
      flex-grow: 1;
      justify-content: end;
    }

    a:first-child {
      @include font(14px, 24px);
      font-weight: 700;
      letter-spacing: 0.01em;
      color: $COLOR_1;
    }

    a:not(:first-child) {
      @include font(15px, 24px);
      letter-spacing: 0.01em;
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;
    }
  }

  &__general-links {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding-bottom: 40px;

    @include tablet {
      flex-direction: row;
      padding-bottom: 16px;
      justify-content: center;
    }

    @include desktop {
      padding: 0;
    }

    a {
      @include font(13px, 24px);
      font-weight: 600;
      letter-spacing: 0.01em;
      color: rgba(255, 255, 255, 0.8);
      cursor: pointer;

      @include tablet {
        line-height: 20px;
      }
    }

    a:not(:first-child) {
      @include tablet {
        &:before {
          content: '|';
          padding: 0 15px;
          cursor: auto;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }

  &__copyright {
    @include font(11px, 20px);
    font-weight: 600;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.8);

    @include tablet {
      font-size: 13px;
      text-align: center;
    }
  }
}
