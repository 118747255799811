@import '@styles/settings/colors';
@import '@styles/settings/variables';

@mixin font($size: 14px, $height: 14px) {
  font-family: $font-main;
  font-style: normal;
  font-weight: 400;
  font-size: $size;
  line-height: $height;
  color: $COLOR_2;
}
