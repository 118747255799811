@import '@styles/settings/index';
@import '@styles/mixins/index';

#change-payment-form {
  border: 1px solid $COLOR_9;
  border-radius: 2px;
  margin-top: 36px;
  height: 58px;
  padding: 20px 7px;

  @include tablet {
    margin: 0;
  }
}
