@import '@styles/settings/index';
@import '@styles/mixins/index';

.navigation {
  background: $COLOR_7;
  border: 1px solid $COLOR_8;
  position: relative;
  z-index: -1;
  min-height: 61px;
  padding: 0 60px;
  box-sizing: border-box;
  display: none;

  @include desktop {
    display: flex;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    width: 100%;
    margin: auto;
  }

  &__items {
    font-family: $font-main;
    font-size: 16px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.05em;
    text-align: left;
    color: $COLOR_2;
    padding: 0;
    margin: 0;
    display: flex;
  }

  &__item-wrapper {
    display: flex;
  }

  &__item {
    display: inline-flex;
    width: max-content;
    color: $COLOR_2;
    text-decoration: none;

    &:hover {
      color: $COLOR_2;
      opacity: 0.8;
    }

    &::after {
      content: ' ';
      height: 5px;
      width: 5px;
      border: solid $COLOR_2;
      border-width: 0 2px 2px 0;
      align-self: center;
      margin: 0 49px 0 13px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);

      &:hover {
        opacity: 0.8;
      }
    }
  }

  a {
    text-decoration: none;
  }

  &__cta-btn {
    min-width: 251px;
  }
}
