@import '@styles/settings/index';
@import '@styles/mixins';

.order-confirmation {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 40px 16px;

  @include tablet {
    padding: 36px 48px 96px 48px;
  }

  @include desktop {
    padding: 40px 80px 160px 80px;
  }

  &__title-wrapper {
    max-width: 1440px;
    width: 100%;
    margin: auto;

    &--title {
      display: flex;
      align-items: center;

      svg {
        height: 16px;
        width: 16px;
        margin-right: 8px;

        @include tablet {
          height: 32px;
          width: 32px;
          margin-right: 16px;
        }

        @include desktop {
          height: 64px;
          width: 64px;
        }
      }

      span {
        @include font(16px, 28px);
        font-weight: 700;

        @include tablet {
          font-size: 32px;
          line-height: 40px;
        }

        @include desktop {
          font-size: 56px;
          line-height: 64px;
          font-weight: 800;
        }
      }
    }

    &--message {
      @include font(14px, 26px);
      padding: 16px 0;

      @include tablet {
        font-size: 16px;
        line-height: 32px;
        padding: 36px 0 24px;
      }

      span {
        font-weight: 700;
      }
    }
  }

  &__container {
    @include desktop {
      display: flex;
    }
    max-width: 1440px;
    width: 100%;
    margin: auto;
  }

  &__steps-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include tablet {
      margin-bottom: 40px;
    }

    @include desktop {
      margin-right: 32px;
      margin-bottom: 0;
    }
  }

  .regularText {
    color: $COLOR_2;
    padding: 16px 0;

    @include tablet {
      padding: 24px 0;
    }
  }

  &__subtitle {
    @include font(16px, 28px);
    letter-spacing: -0.01em;
    font-weight: 600;
    border-bottom: solid 1px $COLOR_5;
    padding-bottom: 8px;

    @include tablet {
      font-size: 32px;
      line-height: 40px;
      font-weight: 700;
      padding-bottom: 16px;
      margin-bottom: 24px;
    }

    &_no-border {
      border-bottom: none;
      margin: 0;

      @include tablet {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }

  &__vitalsource-link {
    @include font(14px, 26px);
    font-weight: 600;
    line-height: 26px;
    cursor: pointer;
    text-decoration: underline;

    @include tablet {
      font-size: 16px;
      line-height: 32px;
      font-weight: 700;
    }
  }

  &__link {
    font-weight: 700;

    &:hover {
      color: $COLOR_2;
      opacity: 0.8;
    }
  }

  &__payment-details {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    @include tablet {
      flex-direction: row;
      padding-bottom: 40px;
    }

    div:first-child {
      margin-bottom: 16px;

      @include tablet {
        margin: 0;
      }
    }
  }

  &__purchased-items {
    div:not(:first-child) {
      border-top: none;
    }
  }

  .order-summary-confirmation {
    h2 {
      @include font(16px, 28px);
      font-weight: 600;
      letter-spacing: 0.0025em;

      @include tablet {
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
      }
    }
  }

  .order-summary {
    top: 190px;
  }

  .book-item__info-main-image {
    @include tablet {
      width: 132px;
    }
  }
}

.payment-details {
  &__column {
    display: flex;
    flex-direction: column;
    flex: 50%;
  }

  &__item {
    @include font(16px, 32px);
    letter-spacing: -0.05em;
    margin: 0;

    &_bold {
      font-weight: 700;
    }

    &_secondary-color {
      color: $COLOR_14;
    }
  }
}

.order-summary-confirmation {
  margin-top: 24px;

  @include tablet {
    margin-top: 0px;
  }
}
