@import '@styles/settings/index';
@import '@styles/mixins';

.page-login {
  width: 90%;
  max-width: 788px;
  margin: 24px auto 80px;

  @include tablet {
    margin: 40px auto 160px;
  }

  h1 {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 8px;

    @include tablet {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 24px;
    }
  }

  .create-account {
    margin-bottom: 24px;
  }

  @include font(16px, 32px);

  .inner-wrapper {
    max-width: 296px;
    margin: 0 auto;
  }
}
