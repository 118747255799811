@import '@styles/settings/index';

.login-account {
  .create-account-btn {
    display: block;
    font-family: $font-main;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.01em;
    text-align: left;
    color: $COLOR_2;
    text-decoration: underline;
    margin-top: 32px;
    margin-bottom: 24px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
  }

  .login-error-msg {
    display: block;
    font-family: $font-main;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.005em;
    color: $COLOR_10;
    margin-bottom: 24px;
  }

  .form-text {
    display: block;
    font-family: $font-main;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.005em;
    color: $COLOR_6;
    margin-bottom: 16px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px $COLOR_5;
    padding-bottom: 32px;
  }

  &__email-field {
    display: grid;

    label {
      font-family: $font-main;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.0025em;
      color: $COLOR_2;
    }

    input {
      margin-bottom: 24px;
      height: 46px;
      width: 100%;
      max-width: 296px;
      border: 1px solid $COLOR_9;
      border-radius: 2px;
      padding: 16px 24px 16px 24px;
      color: $COLOR_2;
      font-family: $font-main;
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: -0.01em;
    }

    &--error {
      @extend .login-account__email-field;
      input {
        border: 1px solid $COLOR_10;
      }
    }
  }

  &__password-field {
    @extend .login-account__email-field;

    &--error {
      @extend .login-account__password-field;
      input {
        border: 1px solid $COLOR_10;
      }
    }
  }

  &__submit-btn {
    grid-column: 1;
    width: 100%;
    height: 46px;
    max-width: 296px;
    background-color: $COLOR_11;
    color: $COLOR_1;
    font-family: $font-main;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.01em;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.15s ease-in;
    &:disabled {
      background-color: $COLOR_7;
      cursor: default;
      border: 1px solid $COLOR_9;
      color: $COLOR_9;
    }
    &:hover:enabled {
      background-color: $COLOR_12;
    }
    &:focus:enabled {
      background-color: $COLOR_13;
    }
  }
}
