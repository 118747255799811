$mobile-sm: 320px;
$mobile-md: 375px;
$mobile-lg-width: 425px;
$tablet-width: 768px;
$tablet-lg-width: 1025px;
$desktop-width: 1280px;
$desktop-lg-width: 1601px;

$tablet: 768px;
$desktop: 1200px;

$font-main: 'Open Sans', sans-serif;
