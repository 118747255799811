@import '@styles/settings/index';
@import '@styles/mixins/index';

.modal {
  width: 288px;
  background-color: $COLOR_1;
  display: flex;
  flex-direction: column;
  padding: 48px 16px 25px 16px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-out;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  z-index: 200;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

  @include tablet {
    width: 375px;
    padding: 60px 28px 60px 28px;
  }

  &__close-btn {
    position: absolute;
    right: 11px;
    top: 11px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 5px;
    z-index: 250;

    @include tablet {
      top: 16px;
    }

    &:hover {
      opacity: 0.8;
    }

    &::after {
      content: '\00d7';
      color: $COLOR_2;
      font-size: 30px;
      line-height: 15px;
      display: flex;
      height: 16px;
      width: 16px;
    }
  }

  &__title {
    @include font(18px, 24px);
    font-weight: 600;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 40px;
    left: 0;
    right: 0;
    text-align: center;

    @include tablet {
      top: 16px;
    }
  }

  &__content {
    @include font(14px, 26px);
    text-align: center;
    margin-bottom: 24px;

    @include tablet {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 36px;
    }
  }

  &__action-btn {
    margin-bottom: 25px;

    @include tablet {
      margin-bottom: 33px;
      min-width: 250px;
      width: fit-content;
      align-self: center;
    }
  }

  &__back-btn {
    @include font(14px, 18px);
    font-weight: 600;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }

    &::after {
      content: ' ';
      display: inline-block;
      height: 5px;
      width: 5px;
      margin-left: 13px;
      border: solid $COLOR_2;
      border-width: 0 2px 2px 0;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      vertical-align: middle;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(49, 49, 49, 0.7);
  opacity: 0.75;
}

.text-bold {
  font-weight: 700;
}

.noscroll {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}

.payment-modal {
  width: 100%;
  max-width: 540px;
}
