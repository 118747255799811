@import '@styles/settings/index';
@import '@styles/mixins/index';

.nav-menu {
  display: none;
  margin-right: -13px;
  gap: 10px;

  @include tablet {
    display: flex;
  }

  .account-icon {
    position: relative;

    .user-icon {
      font-size: 11px;
      line-height: 14px;
      font-weight: 700;
      color: $COLOR_1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: $COLOR_11;
      border: 2px $COLOR_11 solid;
      z-index: 101;
    }

    &:hover {
      .user-icon {
        background: $COLOR_1;
        color: $COLOR_14;
        border-color: $COLOR_1;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }

  &__mobile-menu {
    color: $COLOR_2;
    display: flex;
    font-size: 14px;
    line-height: 26px;
    align-items: center;
    z-index: 92;

    .burger-menu {
      margin-left: 8px;
    }

    @include tablet {
      display: none;
    }
  }

  .link-icon {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .icon-svg {
      z-index: 101;
      width: 21px;
      height: 21px;

      path {
        stroke: $COLOR_2;
      }
    }

    .languages-expand-icon {
      width: 5px;
      stroke-width: 2;
      padding-left: 3px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $COLOR_14;
      border-radius: 28px;
      transform: scale(0);
      transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover {
      &:after,
      .user-dropdown,
      .lang-dropdown {
        transform: scale(1);
      }
      .icon-svg {
        transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

        path {
          stroke: $COLOR_1;
        }
      }
      .user-icon {
        background: $COLOR_1;
        color: $COLOR_14;
        border-color: $COLOR_1;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }
}

.mobile-nav-menu {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 0;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(100%, 0, 0);
  z-index: 91;
  background-color: white;

  &.open {
    transform: translate3d(0, 0, 0);
  }

  .navigation {
    display: flex;
    flex-direction: column;
    padding: 40px 16px 32px;
    border-top: 1px solid $COLOR_5;
    background-color: transparent;
    &__container {
      flex-direction: column;
    }
    &__items {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
    }
    &__item-wrapper {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 28px;
      }
    }
    &__item {
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        background-color: $COLOR_2;
        height: 1px;
        opacity: 0;
      }

      &.active {
        font-weight: 600;
        &:before {
          opacity: 1;
        }
      }

      &:after {
        display: none;
      }
    }
  }

  .section-extra {
    width: 100%;
    padding: 40px 24px;
    border-top: 1px solid $COLOR_5;
    border-bottom: 1px solid $COLOR_5;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;

    .user-dropdown__selection-box,
    .lang-dropdown__selection-box {
      border: none;
      box-shadow: none;
      background-color: transparent;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
    }

    .user-dropdown__selection-box{
      a,button{padding-bottom: 32px;}
    }

    .lang-dropdown__selection-box button
    {
      margin-top:32px;
    }

    .lang-dropdown button,
    .user-dropdown__login-btn,
    .user-dropdown__logout-btn,
    .user-dropdown__my-account-btn {
      text-align: left;
      border: none;
      padding: 0;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      &:hover{
        background-color: transparent;
      }
      &::after{
        display: none;
      }
    }

    .lang-dropdown button:not(:last-child)::after {
      display: none;
    }
  }

  .lang-dropdown,
  .user-dropdown {
    padding-top: 0;
    position: relative;
    top: auto;
    transform: scale(1);
  }

  @include tablet {
    display: none;
  }
}
