@import '@styles/settings/index';
@import '@styles/mixins';

.pdp-3 {
  display: grid;
  gap: 40px;
  margin-bottom: 40px;

  @include tablet {
    gap: 60px;
    margin-bottom: 60px;
  }

  @include desktop {
    gap: 80px;
    margin-bottom: 80px;
  }

  &__faq-wrapper .text-panel-wrapper {
    margin-bottom: 16px;
    padding: 0;

    @include tablet {
      margin-bottom: 8px;
    }
  }

  &__stack-panel-wrapper {
    display: grid;
    gap: 24px;

    @include tablet {
      gap: 40px;
    }
  }

  &__faq-wrapper {
    padding: 0 16px;
    width: 100%;

    @include tablet {
      gap: 32px;
    }
  }

  &__faq-container {
    max-width: 788px;
    margin: auto;
    display: grid;
    gap: 24px;
  }
}
