@import '@styles/settings/index';
@import '@styles/mixins/index';

.burger-menu{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    position: relative;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: $COLOR_2;
    font-size: 0;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate3d(0,-0,0);
    &:before,&:after{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 4px;
      background-color: $COLOR_2;
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
    &:before{
      transform: translate3d(0,-7px,0);
    }
    &:after{
      transform: translate3d(0,7px,0);
    }
  }
  &.open{
    span{
      transform: translate3d(-24px,0,0);
      background-color: transparent;

      &:before{
        transform: rotate(45deg) translate3d(18px,-18px,0);
      }
      &:after{
        transform: rotate(-45deg) translate3d(18px,18px,0);
      }
    }
  }

}