@import '@styles/settings/index';
@import '@styles/mixins/index';

.navigation-container {
  z-index: 100;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.13);

  .header {
    display: flex;
    width: 100%;
    height: 56px;
    background: $COLOR_16;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: $COLOR_8;
    padding: 0 24px;

    @include tablet {
      height: 80px;
      padding: 0 60px;
    }

    @include desktop {
      height: 100px;
    }

    &__container {
      align-items: center;
      display: flex;
      width: 100%;
      max-width: 1440px;
      margin: auto;
      justify-content: space-between;
    }

    &_logged {
      justify-content: space-between;
    }

    &__subheading {
      @include font(16px, 32px);
      letter-spacing: -0.005em;
      text-align: left;
      white-space: nowrap;
      flex-grow: 1;

      @include tablet {
        font-size: 24px;
        line-height: 40px;
        letter-spacing: -0.01em;
      }

      @include desktop {
        font-size: 32px;
      }

      &::before {
        content: '|';
        margin: 0 8px;

        @include tablet {
          margin: 0 16px;
        }
      }
    }

    &__logo {
      display: block;
      width: 76px;
      height: 16px;

      @include tablet {
        width: 95px;
        height: 20px;
      }

      @include desktop {
        width: 133px;
        height: 28px;
      }
    }
  }
}
