@import '@styles/settings/index';
@import '@styles/mixins/index';

.lang-dropdown {
  position: absolute;
  top: -8px;
  padding-top: 72px;
  transform: scale(0);
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  button {
    @include font(14px, 46px);
    cursor: pointer;
    border: none;
    position: relative;
    text-align: left;
    width: 100%;
    background-color: transparent;
    padding: 0 16px;
    text-align: center;
    position: relative;
    margin: 0;

    &:hover {
      background-color: $COLOR_7;
    }

    &:not(:last-child)::after {
      content: '';
      width: calc(100% - 32px);
      height: 1px;
      background: $COLOR_5;
      position: absolute;
      bottom: 0;
      left: 16px;
    }

    .selected-lang {
      display: inline-flex;
      align-items: baseline;
      font-weight: 700;

      svg {
        margin-right: 4px;
      }
    }
  }

  &__selection-box {
    width: 100%;
    background-color: $COLOR_1;
    border: 1px solid $COLOR_9;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  }
}
