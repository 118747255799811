@import '@styles/settings/index';
@import '@styles/mixins/index';

.order-summary-desktop {
  position: relative;
  width: 100%;
  display: none;
  padding-top: 5px;

  @include tablet {
    max-width: 460px;
    display: block;
  }

  @include desktop {
    margin-left: auto;
  }

  .order-summary {
    @include desktop {
      position: sticky;
      top: 110px;
    }

    &-empty {
      h2 {
        display: none;
        @include tablet {
          display: block;
        }
      }
    }

    &__wrapper {
      background-color: $COLOR_1;
    }

    &__title {
      @include font(16px, 28px);
      font-weight: 600;
      letter-spacing: -0.005em;

      @include tablet {
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
      }
    }

    &__content,
    &__content--hidden {
      height: auto;
      width: 100%;

      @include tablet {
        border-radius: 2px;
        margin-top: 16px;
        padding: 32px;
        border: 1px solid $COLOR_4;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.13);
      }
    }

    &__item-tax {
      display: flex;
      border-bottom: solid 1px $COLOR_5;
      padding-top: 8px;

      &--left {
        @include font(14px, 26px);
        letter-spacing: 0.0025em;
        width: 100%;
        padding-bottom: 8px;
        width: auto;
        align-self: center;
      }

      &--right {
        @include font(14px, 40px);
        letter-spacing: 0.0025em;
        padding-bottom: 8px;
        margin-left: auto;

        &-bold {
          @extend .order-summary__item-tax--right;

          font-size: 18px;
          font-weight: 700;
          line-height: 32px;
          align-self: flex-end;
        }
      }
    }

    &__code-applied {
      display: flex;
      border-bottom: solid 1px $COLOR_5;
      padding-top: 8px;
      position: relative;

      &--left {
        @include font(14px, 26px);
        letter-spacing: 0.0025em;
        width: 100%;
        padding-bottom: 8px;
        width: auto;
        align-self: center;
      }

      &--right {
        @include font(18px, 32px);
        letter-spacing: 0.0025em;
        padding-bottom: 8px;
        margin-left: auto;
        font-weight: 700;
        align-self: flex-end;
      }

      &--discount-price {
        color: $COLOR_11;
      }

      &--original-price {
        @include font(18px, 32px);
        color: #777777;
        text-decoration: line-through;
        margin-right: 16px;
      }

      &--cancel-btn {
        height: 16px;
        width: 16px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        transform: rotate(45deg);
        padding: 0;
        margin-top: 8px;
        margin-left: 8px;
        transition: all 0.15s ease-in;

        &:hover {
          opacity: 0.8;
        }

        @include tablet {
          position: absolute;
          right: -24px;
          top: 16px;
          margin: 0;
        }
      }
    }

    &__item-total {
      @extend .order-summary__item-tax;

      border-bottom: none;

      &--left {
        @extend .order-summary__item-tax--left;
      }

      &--right-bold {
        @extend .order-summary__item-tax--right-bold;
      }
    }

    &__empty-cart-msg {
      display: none;

      @include tablet {
        display: block;
        margin-bottom: 16px;
      }
    }

    &__agreement {
      @include font(16px, 32px);
      letter-spacing: -0.005em;
      display: none;

      @include tablet {
        display: block;
      }

      &--text {
        display: block;
        margin-bottom: 32px;
      }

      &--link {
        text-decoration: underline;
        font-weight: 700;
        cursor: pointer;
      }
    }

    &__view-summary-btn {
      display: none;
    }

    &__add-promo-btn {
      @include font(16px, 32px);
      font-weight: 700;
      text-decoration: underline;
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding: 16px 0 0 0;
      display: flex;
      align-items: center;
      transition: all 0.15s ease-in;

      &:hover {
        opacity: 0.8;
      }

      @include tablet {
        padding: 16px 0 24px 0;
      }

      .plus-icon {
        margin-right: 8px;
        min-width: 16px;
      }
    }

    &__promo-controls {
      padding-top: 16px;

      label {
        @include font(14px, 26px);
      }

      input {
        @include font(14px, 14px);
        box-sizing: border-box;
        height: 46px;
        width: 100%;
        border: 1px solid $COLOR_9;
        border-radius: 2px;
        padding: 16px 24px 16px 24px;
        font-weight: 600;
      }

      span {
        @include font(16px, 32px);
        font-weight: 700;
        color: $COLOR_10;
      }

      .primary-cta {
        margin: 24px 0 16px 0;
      }
    }
  }

  .order-summary-item {
    @include font(14px, 26px);
    display: flex;
    border-bottom: solid 1px $COLOR_5;
    letter-spacing: 0.0025em;
    padding: 8px 0;

    &__name {
      align-self: flex-end;
      align-self: center;
      padding-right: 10px;
    }

    &__price {
      align-self: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      margin-left: auto;
    }
  }
}

.order-summary-cart,
.order-summary-confirmation {
  @extend .order-summary-desktop;
  display: block;
}

.order-summary-cart {
  .order-summary__wrapper {
    h2 {
      font-size: 16px;
      line-height: 28px;
      font-weight: 600;

      @include tablet {
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
      }
    }

    .order-summary__submit-btn {
      margin-top: 16px;
    }
  }
}

.order-summary-mobile {
  @extend .order-summary-desktop;

  display: block;
  max-width: none;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.13);
  padding: 0 16px;
  background: $COLOR_1;

  @include tablet {
    display: none;
  }

  .order-summary {
    &__wrapper {
      position: initial !important;

      h2 {
        display: none;

        @include tablet {
          display: block;
        }
      }
    }

    &__title {
      display: none;
    }

    &__content {
      margin-top: 0;
      width: calc(100% + 32px);
      margin-left: -16px;
      padding: 16px;

      @include tablet {
        width: 100%;
        margin-left: 0;
        padding: 22px 32px;
      }

      &--hidden {
        @extend .order-summary__content;

        .order-summary-item,
        .order-summary__item-tax,
        .order-summary__add-promo-btn,
        .order-summary__code-applied {
          display: none;
        }

        .order-summary__item-total {
          display: flex;
        }
      }

      .order-summary__item-total--left {
        padding-bottom: 0;
      }

      .order-summary__item-total--right-bold {
        float: right;
        padding-bottom: 0;
      }
    }

    &__submit-btn {
      display: none;
    }

    &__view-summary-btn {
      display: block;
      margin-top: 16px;
    }
  }
}
