@import '@styles/settings/index';
@import '@styles/mixins/index';

.user-dropdown {
  position: absolute;
  top: -8px;
  padding-top: 72px;
  transform: scale(0);
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &__login-btn,
  &__logout-btn,
  &__my-account-btn {
    @include font(14px, 46px);
    font-weight: 600;
    cursor: pointer;
    border: none;
    position: relative;
    text-align: left;
    width: 100%;
    background-color: transparent;
    padding: 0 16px;
    text-align: center;
    position: relative;
    white-space: nowrap;
    margin: 0;

    &:hover {
      background-color: $COLOR_7;
    }
  }

  &__my-account-btn::after {
    content: '';
    width: calc(100% - 32px);
    height: 1px;
    background: $COLOR_5;
    position: absolute;
    bottom: 0;
    left: 16px;
  }

  &__selection-box {
    width: 100%;
    background-color: $COLOR_1;
    border: 1px solid $COLOR_9;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  }
}
