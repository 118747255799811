@import '@styles/settings/index';
@import '@styles/mixins/index';

.payment-form {
  width: 100%;
  margin-top: 24px;

  @include tablet {
    max-width: 460px;
  }

  @include desktop {
    width: 30vw;
  }

  &__stripe-wrapper {
    border: 1px solid $COLOR_9;
    height: 46px;
    padding: 13px 24px;
    border-radius: 2px;
  }

  &__error-msg {
    @include font(16px, 32px);
    font-weight: 700;
    letter-spacing: -0.005em;
    color: $COLOR_10;
    margin-top: 24px;
  }

  &__agreement {
    @include font(16px, 32px);
    display: inline-block;
    letter-spacing: -0.01em;
    align-items: center;
    margin-top: 32px;

    &--link {
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
      margin-left: 3px;
    }
  }

  &__submit-btn {
    margin-top: 32px;
  }
}
