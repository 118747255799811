@import '@styles/settings/index';
@import '@styles/mixins/index';

.hero-img {
  background: $COLOR_2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  min-height: 200px;

  &__overlay-img {
    background-size: cover;
    background-position: center 0;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  &__content-wrapper {
    display: grid;
    gap: 16px;
    width: 100%;

    @include tablet {
      justify-content: center;
      gap: 40px;
    }
  }

  h3 {
    @include font(16px, 28px);
    font-weight: 800;
    color: $COLOR_1;
    z-index: 1;
    text-align: center;
    margin: 0;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.3);

    @include tablet {
      font-size: 56px;
      line-height: 64px;
      max-width: 788px;
    }
  }

  p {
    @include font(14px, 28px);
    font-weight: 600;
    color: $COLOR_1;
    z-index: 1;
    max-width: 628px;
    margin: 0;
    display: none;

    @include tablet {
      font-size: 18px;
      line-height: 32px;
      max-width: 788px;
      display: block;
    }
  }

  a {
    z-index: 1;
    text-decoration: none;

    @include tablet {
      justify-self: center;
    }
  }

  button {
    min-width: 192px;

    // Uncomment if decision is made to use modified design for button
    // height: 62px;
    // font-size: 16px;
    // font-weight: 700;
    // border: 1px solid $COLOR_3;
    // color: $COLOR_3;

    @include tablet {
      width: auto;
    }
  }
}

.mobile-content p {
  @include font(16px, 26px);
  display: block;
  padding: 16px 16px 0 16px;
  text-align: center;

  @include tablet {
    display: none;
  }
}

.hero-size-sm {
  height: auto;

  @include tablet {
    height: 300px;
  }
}

.hero-size-md {
  height: auto;

  @include tablet {
    height: 540px;
  }
}

.hero-size-lg {
  height: auto;

  @include tablet {
    height: 754px;
  }

  @include desktop {
    height: 840px;
  }
}
