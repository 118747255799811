@import '@styles/settings/index';
@import '@styles/mixins/index';

.checkout {
  display: grid;
  padding: 24px 16px 40px 16px;

  @include tablet {
    padding: 40px 48px 96px 48px;
  }

  @include desktop {
    padding: 40px 80px 160px 80px;
  }

  &__container {
    max-width: 1440px;
    margin: auto;
    width: 100%;

    @include desktop {
      display: flex;
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include desktop {
      margin-right: 32px;
    }
  }

  &__title {
    @include font(16px, 28px);
    font-weight: 700;
    letter-spacing: -0.01em;
    border-bottom: solid 1px $COLOR_5;
    padding-bottom: 8px;

    &-num {
      margin-right: 16px;
    }

    @include tablet {
      font-size: 32px;
      line-height: 40px;
      padding-bottom: 16px;

      &-num {
        margin-right: 24px;
      }
    }

    &--inactive {
      @extend .checkout__title;
      color: $COLOR_6;
    }
  }

  &__edit-btn {
    @include font(14px, 26px);
    font-weight: 700;
    letter-spacing: -0.005em;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    float: right;

    @include tablet {
      font-size: 24px;
      line-height: 36px;
      margin-left: 40px;
      float: none;
    }
  }

  &__current-user {
    margin: 16px 0 24px 0;

    @include tablet {
      margin: 32px 0 40px 0;
    }

    span {
      font-weight: 700;
    }
  }

  &__user-info {
    display: grid;
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 40px;
    }

    span:first-child {
      color: $COLOR_6;
      margin: 16px 0;
      max-width: 459px;

      @include tablet {
        margin: 32px 0 24px 0;
      }
    }

    span:not(:first-child) {
      font-size: 16px;
      line-height: 32px;
      color: $COLOR_2;
    }

    span:nth-child(2) {
      font-weight: 700;
    }
  }

  &__lock-icon {
    margin-left: 12px;
    stroke: $COLOR_2;
    fill: $COLOR_2;
    height: 16px;

    @include tablet {
      margin-left: 16px;
      height: 24px;
    }

    &--inactive {
      @extend .checkout__lock-icon;
      stroke: $COLOR_6;
      fill: $COLOR_6;
    }
  }

  .order-summary-desktop {
    @include tablet {
      margin-top: 40px;
    }

    @include desktop {
      margin: 0;
    }
  }
}
