@import '@styles/settings/index';
@import '@styles/mixins/index';

.mobile-cta-container {
  background-color: $COLOR_1;
  width: 100%;
  padding: 16px;
  position: sticky;
  top: 0;
  z-index: 100;
  border: 1px solid $COLOR_4;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.13);
  border-radius: 2px;

  button {
    width: 100%;

    svg {
      margin-left: 10px;
      min-width: 16px;
      width: 16px;
      height: 16px;
    }
  }

  @include tablet {
    display: none;
  }
}

.subscription {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 40px 16px;

  @include tablet {
    padding: 40px 48px 96px 48px;
  }

  @include desktop {
    padding: 40px 80px 160px 80px;
  }

  .book-item__info-main-image {
    @include tablet {
      margin-right: 24px;
    }
    @include desktop {
      width: 132px;
      margin-right: 32px;
    }
  }

  .book-item__info-main-image img {
    @include tablet {
      height: 208px;
    }

    @include desktop {
      height: 166px;
    }
  }

  .book-item__title {
    @include tablet {
      font-size: 32px;
      line-height: 40px;
    }

    @include desktop {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: min-content;
    width: 100%;
    max-width: 788px;
    margin: auto;
  }

  &__content-wrapper {
    @include tablet {
      display: flex;
    }

    @include desktop {
      display: block;
    }
  }

  &__content {
    width: 100%;
  }

  &__data-active,
  &__data-inactive {
    display: flex;
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;

    @include desktop {
      grid-row: 2;
      grid-column: 2;
    }
  }

  &__item-wrapper {
    margin-bottom: 24px;

    .book-item {
      border-top: none;
    }

    .spinner {
      margin-top: 16px;

      @include tablet {
        margin-top: 24px;
      }
    }

    @include tablet {
      margin-bottom: 32px;
    }

    @include desktop {
      margin-bottom: 40px;
    }
  }

  &__title-container {
    display: flex;
    border-bottom: 1px solid $COLOR_5;
    padding-bottom: 8px;

    @include tablet {
      align-items: center;
      justify-content: space-between;
      padding-bottom: 24px;
    }

    span {
      @include font(16px, 28px);
      font-weight: 600;

      @include tablet {
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
      }
    }

    button {
      display: none;

      @include tablet {
        width: 332px;
        display: block;
      }

      span {
        @include font(14px, 14px);
        color: $COLOR_1;
      }

      svg {
        margin-left: 10px;
        vertical-align: text-top;
      }
    }

    .spinner {
      margin-top: 0;
    }
  }

  &__info-wrapper {
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 40px;
    }
  }

  &__title {
    @include font(16px, 28px);
    display: block;
    text-align: left;
    font-weight: 600;
    padding-bottom: 8px;
    border-bottom: 1px solid $COLOR_5;
    margin-bottom: 16px;

    @include tablet {
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 24px;
      padding-bottom: 16px;
    }
  }

  &__cancel-btn,
  &__change-billing-btn {
    @include font(16px, 28px);
    font-weight: 600;
    padding: 0;
    width: fit-content;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    text-align: start;

    @include tablet {
      line-height: 32px;
      margin-top: 24px;
      font-weight: 700;
    }
  }

  &__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 375px;
    margin-top: 154px;
    border-radius: 2px;
  }
}

.sub-information {
  display: flex;
  flex-direction: column;

  &__column-container {
    display: block;

    @include tablet {
      display: flex;
    }
  }

  &__billing-container {
    margin-bottom: 24px;
  }

  &__billing-container,
  &__payment-container {
    display: flex;
    flex-direction: column;

    @include tablet {
      max-width: 328px;
      width: 50%;
      margin: 0;
      justify-content: space-between;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    @include tablet {
      max-width: 328px;
      margin: 0;
      width: 50%;
    }
  }

  &__text {
    &_gray {
      @include font(16px, 32px);
      display: block;
      color: $COLOR_6;
    }

    &_black {
      @include font(16px, 32px);
    }

    &_price {
      font-weight: 700;
    }

    &_bold {
      @include font(16px, 32px);
      display: block;
      font-weight: 700;
    }
  }

  &__change-payment-btn {
    @include font(16px, 28px);
    font-weight: 600;
    padding: 0;
    width: fit-content;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    text-align: start;

    @include tablet {
      line-height: 32px;
      margin-top: 24px;
      font-weight: 700;
    }
  }
}

.sub-modal {
  &__title {
    @include font(14px, 26px);
    letter-spacing: 0.0025em;
    display: block;
    margin: 24px auto;
    text-align: center;

    &-bold {
      font-weight: 700;
    }

    @include tablet {
      font-size: 18px;
      line-height: 24px;
      margin: 36px auto;
    }
  }

  &__button {
    @include font(14px, 14px);
    color: $COLOR_1;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 46px;
    margin: 0 auto 24px;
    border-radius: 2px;
    background-color: $COLOR_11;
    cursor: pointer;
    border: none;
    transition: all 0.15s ease-in;

    @include tablet {
      margin: 0 auto 36px;
      width: 208px;
    }

    :disabled {
      background-color: $COLOR_15;
      cursor: default;
      border: 1px solid $COLOR_9;
      color: $COLOR_9;
    }
    :hover:enabled {
      background-color: $COLOR_12;
    }
    :focus:enabled {
      background-color: $COLOR_13;
    }
  }

  &__button-back {
    @include font(14px, 14px);
    display: block;
    margin: auto;
    border: none;
    background-color: $COLOR_1;
    font-weight: 600;

    @include tablet {
      margin: 0 auto 36px;
    }

    &:hover {
      color: $COLOR_2;
    }
  }
}

.no-sub {
  padding: 24px 16px 80px;

  @include tablet {
    padding: 40px 16px 160px;
  }

  &__info-wrapper {
    width: 100%;
    max-width: 788px;
    margin: auto;
  }

  &__title {
    @include font(16px, 28px);
    font-weight: 600;
    display: block;
    padding-bottom: 8px;
    border-bottom: 1px solid $COLOR_5;
    margin-bottom: 16px;

    @include tablet {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      padding-bottom: 24px;
      margin-bottom: 24px;
    }
  }

  &__message {
    @include font(16px, 32px);
    display: block;
    margin-bottom: 24px;

    @include tablet {
      margin-bottom: 32px;
    }

    &--bold {
      font-weight: 700;
    }
  }

  .primary-cta {
    @include tablet {
      display: block;
      margin: auto;
      max-width: 277px;
    }
  }
}

.page-spinner {
  border: 2px solid #adadad;
  border-top: 2px solid #094f34;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
  margin: 150px auto 150px auto;
}
