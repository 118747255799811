@import '@styles/settings/index';
@import '@styles/mixins/index';

.book-item {
  display: grid;
  padding: 16px 0;
  border-top: 1px solid $COLOR_5;
  border-bottom: 1px solid $COLOR_5;
  position: relative;

  @include tablet {
    display: flex;
    padding: 24px 0;
  }

  &__x-btn {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: -5px;
    padding: 5px;
    &::after {
      content: '×';
      color: $COLOR_2;
      font-size: 25px;
    }

    @include tablet {
      display: block;
    }
  }

  &__info-main {
    display: flex;
    margin-bottom: 10px;

    @include tablet {
      margin: 0;
    }

    &-image {
      text-align: center;
      margin-right: 16px;
      width: 69px;

      @include tablet {
        margin-right: 32px;
        width: 166px;
      }

      img {
        min-width: 69px;
        width: 100%;
      }
    }
  }

  &__content {
    width: 100%;
    display: grid;

    @include tablet {
      align-content: space-between;
    }
  }

  &__title,
  &__title-mobile {
    text-decoration: none !important;

    @include font(14px, 26px);
    font-weight: 700;
    letter-spacing: 0.0025em;
    text-decoration: underline;

    @include tablet {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
    }
  }

  &__title {
    display: none;

    @include tablet {
      display: block;
    }
  }

  &__title-mobile {
    display: block;

    @include tablet {
      display: none;
    }
  }

  &__info-wrapper {
    display: grid;
    row-gap: 13px;

    @include tablet {
      display: flex;
      justify-content: space-between;
    }
  }

  &__date,
  &__price {
    display: flex;
    justify-content: space-between;

    @include tablet {
      display: grid;
    }

    span:first-child {
      @include font(12px, 26px);
      letter-spacing: 0.0025em;
      color: $COLOR_6;

      @include tablet {
        font-size: 16px;
        line-height: 32px;
        letter-spacing: -0.005em;
      }
    }

    span:nth-child(2) {
      @include font(14px, 26px);
      text-align: end;
      padding-left: 10px;
      font-weight: 700;
      letter-spacing: 0.0025em;

      @include tablet {
        padding: 0;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: -0.005em;
      }
    }
  }

  &__date {
    span:nth-child(2) {
      @include tablet {
        text-align: start;
      }
    }
  }

  &__price {
    @include tablet {
      text-align: end;
    }

    span:nth-child(2) {
      @include tablet {
        font-size: 18px;
        letter-spacing: 0.0025em;
      }
    }
  }

  &__remove-btn {
    @include font(12px, 26px);
    font-weight: 700;
    letter-spacing: 0.0025em;
    margin-top: 9px;
    text-decoration: underline;

    @include tablet {
      display: none;
    }
  }
}
