@import '@styles';
@import 'form';

.App {
  min-height: 100vh;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: max-content auto max-content;
}

.page-wrapper {
  background: $COLOR_1;
  width: 100%;
  display: grid;
  align-content: space-between;
  position: relative;
}

.spinner {
  border: 2px solid #adadad;
  border-top: 2px solid #094f34;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
